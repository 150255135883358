body{
  background-color: #f6f7f7;

}
.container-fluid {
  height: 100%;
}


.lateral {
  background-image: url("https://i.pinimg.com/736x/f9/e9/c4/f9e9c4294d4abd55d6e6ae1dd00682bb.jpg");
  height: 100vh; /* Ajusta a la altura completa de la ventana */
  background-size: cover; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
}
.form-control{
  border: 2px rgb(93, 93, 93) solid;}

.formul{
  margin-top: 20%;
}

.cabecera th{
  background-color: rgb(180, 196, 245) !important; 

}
.nombre_hijo{
  font-size: 13px;
  color: black;
}
.edad{
  font-size: 13px;
  color: black;
  font-weight: bold;
}

.na{
  font-size: 20px;
}
.nafil{
  font-size: 20px;
  font-weight: bold;
}